import './styles.scss';
import React, {memo, useEffect, useState, useRef} from 'react';

import {Link, navigate} from "gatsby";

import {
    sandClock,
} from "../../images";

type Props = {
    setLink: string
}

const OnBoardLoading = ({setLink}: Props) => {

    const [percent, setPercent] = useState(1);

    useEffect(() => {
        
        const interval = setInterval(() => {
            setPercent(percent => {
                if(percent === 100){
                    clearInterval(interval);
                }
                return (percent < 100) ? percent + 1 : 100
            });
        }, 60);

        setTimeout(() => {
            navigate(setLink);
        }, 6500);

    }, []);

    return (
        <>
            <div className="onBoardLoading">
                <div className="onBoardLoadingBox">
                    <div className="onBoardLoadingVector">
                        <img src={sandClock} alt="" />
                    </div>
                    {/* <h2 className="onBoardLoadingTitle">This time, stick to it</h2>
                    <p className="onBoardLoadingText">Receive gentle reminders from <br /> AEER Platform help you stay on track</p> */}
                    <h2 className="onBoardLoadingTitle">An offer is getting formed</h2>
                    <p className="onBoardLoadingText">Receive gentle reminders from <br /> AEER Platform help you stay on track</p>
                    
                    <div className="onBoardLoadingProgress">
                        <span className="onBoardLoadingLeft">Loading</span>
                        <div className="onBoardLoadingProgressLine">
                            <span style={{width: `${percent}%`}}></span>
                        </div>
                        <span className="onBoardLoadingNumber">{percent}%</span>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default memo(OnBoardLoading);
